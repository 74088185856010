import React from "react";
import theme from "theme";
import { Theme, Text, Section, Icon, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				K13 Marketing Agency
			</title>
			<meta name={"description"} content={"Heben Sie Ihre Marke auf ein neues Niveau"} />
			<meta property={"og:title"} content={"K13 Marketing Agency"} />
			<meta property={"og:description"} content={"Heben Sie Ihre Marke auf ein neues Niveau"} />
			<meta property={"og:image"} content={"https://glaricorn.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://glaricorn.com/img/43658675681.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glaricorn.com/img/43658675681.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glaricorn.com/img/43658675681.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glaricorn.com/img/43658675681.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glaricorn.com/img/43658675681.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glaricorn.com/img/43658675681.png"} />
			<meta name={"msapplication-TileColor"} content={"https://glaricorn.com/img/43658675681.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0 0px 0"
			sm-padding="40px 0"
		>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
			Kontaktieren Sie uns
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
			Wir würden uns freuen, von Ihnen zu hören und zu besprechen, wie K13 Ihnen helfen kann, Ihre Marketingbemühungen zu verbessern. Sie erreichen uns über folgende Wege:
			</Text>
		</Section>
		<Section
			color="--light"
			padding="30px 0 300px 0"
			sm-padding="40px 0"
			position="relative"
			background="--color-primary"
			quarkly-title="Form-1"
		>
			<Box margin="0px -16px 0px -16px" display="block" flex-wrap="wrap">
				<Box
					display="flex"
					align-self="center"
					flex-wrap="no-wrap"
					justify-content="space-between"
					align-items="flex-start"
					align-content="flex-end"
					flex-direction="row"
					sm-display="block"
				>
					<Box
						sm-padding="64px 0 0 0"
						margin="0 0 0 0"
						max-width="360px"
						position="relative"
						padding="0 0 0 64px"
					>
						<Icon
							size="48px"
							top="0"
							left="0"
							category="md"
							icon={MdLocationOn}
							position="absolute"
						/>
						<Text as="h4" margin="6px 0" font="--base">
						Standort
						</Text>
						<Text as="p" margin="6px 0" font="--headline3">
						Rahdener Str. 93, 32312 Lübbecke
						</Text>
					</Box>
					<Box padding="0 0 0 64px" margin="0 0 0 0" max-width="360px" position="relative">
						<Icon
							left="0"
							category="md"
							icon={MdPhone}
							position="absolute"
							size="48px"
							top="0"
						/>
						<Text font="--base" as="h4" margin="6px 0">
						Telefon
						</Text>
						<Text as="p" margin="6px 0" font="--headline3">
						+49 5741 240570
						</Text>
					</Box>
					<Box
						position="relative"
						padding="0 0 0 64px"
						sm-padding="64px 0 0 0"
						margin="0 0 0 0"
						max-width="360px"
					>
						<Icon
							top="0"
							left="0"
							category="md"
							icon={MdEmail}
							position="absolute"
							size="48px"
						/>
						<Text font="--base" as="h4" margin="6px 0">
							Email
						</Text>
						<Text as="p" margin="6px 0" font="--headline3">
							<Link href="mailto:info@glaricorn.com" text-decoration="none" hover-text-decoration="underline" color="--light">
							info@glaricorn.com
							</Link>
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});